@mixin generateSpacingClasses($prefix, $property) {
  $sizes: [5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px];

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$i} {
      #{$property}: $size !important;
    }
  }
}
