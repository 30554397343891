@include generateSpacingClasses(ml, margin-left);
@include generateSpacingClasses(mr, margin-right);
@include generateSpacingClasses(mt, margin-top);
@include generateSpacingClasses(mb, margin-bottom);

@include generateSpacingClasses(pl, padding-left);
@include generateSpacingClasses(pr, padding-right);
@include generateSpacingClasses(pt, padding-top);
@include generateSpacingClasses(pb, padding-bottom);

* {
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    overflow: initial;
  }
}

body {
  font-family: $font-base;
  color: $text;
  background-color: $bg;
  margin: 0;
  font-size: 14px;
}

input {
  font-family: $font-base;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.container {
  max-width: $container-width;
  padding: 0 16px;
  margin: 0 auto;
}

.slide-up {
  animation: slide-up 0.3s forwards;
}

.fade-in {
  animation: fade-in 0.3s forwards;
}

.top-1 {
  position: relative;
  top: 1px;
}

.top-2 {
  position: relative;
  top: 2px;
}

.fw-medium {
  font-weight: 500;
}

.inline-block {
  display: inline-block;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.font-small {
  font-size: 12px;
}

.circle-radius {
  border-radius: 50%;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disabled {
  pointer-events: none;
  cursor: initial;
  opacity: 0.5;
}
