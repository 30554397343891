.home {
  width: 430px;
  max-width: 95%;
  margin: 100px auto 20px;
  padding: 40px 30px 30px;
  background: #f3f4f8;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  font-weight: normal;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #34404b;

  &__title {
    font-weight: bold;
    color: #34404b;
    font-family: 'Rajdhani';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;

    text-transform: uppercase;
  }

  &__subtitle {
    font-family: 'Rajdhani';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #34404b;
  }

  &__desc {
    font-weight: normal;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #34404b;
    margin: 10px 0 40px;
  }

  &__address {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #34404b;
    img {
      margin-right: 5px;
    }
  }

  &__disconnect {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #34404b;
    display: inline-block;
    transition: all 0.3s;

    &:hover {
      color: #ff527c;
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #34404b;
    padding: 6px 20px;
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      color: #fe6b8b;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 50px auto 10px;
    padding: 20px 15px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MuiInputBase-root {
  color: #34404b !important;
}
.MuiInput-underline {
  &:before {
    border-bottom-color: #34404b !important;
  }
}
