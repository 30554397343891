/* ============ Settings ============ */
$container-width: 1200px;
$image-path: '~src/assets/images/';

/* ============ Fonts ============ */
$font-base: 'Montserrat', sans-serif;
@font-face {
  font-family: "American Captain";
  src: local("American Captain"),
    url("./fonts/American Captain.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Rajdhani";
  src: local("Rajdhani"), url("./fonts/Rajdhani-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("./fonts/OpenSans-Regular.ttf") format("truetype");
}


/* ============ Colors ============ */
$text: #333333;
$bg: #F1F4F8;

$color-1: #23a7b5;
$color-2: #3fa3c4;
$color-3: #093d4e;
$color-4: #0a4b61;
$color-5: #FE6B8B;
$color-6: #FF8E53;

$gradient-1: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);

/* ============ Breakpoints ============ */
$breakpoint-xxl: 1000px;
$breakpoint-xl: 700px;
$breakpoint-l: 600px;
$breakpoint-m: 500px;
$breakpoint-s: 400px;
$breakpoint-xs: 320px;
