.box {
  position: relative;
  margin: 100px auto 20px;
  max-height: 100vh;
  overflow-y: auto;
  // display: flex;
  justify-content: center;

  &__shadow {
    margin: 50px 100px 20px;
    padding: 20px 10px 20px;
    background-color: white;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 15px;
    text-align: center;
    transition: all .3s;
    position: relative;
  }

  &__link {
    transition: all .3s;
    text-decoration: underline;

    &:hover {
      color: #FE6B8B;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
  }
  &__thead {
    background: #fdfdfd;
    background-size: auto 100%;
  tr {
    // font-size: 13px;
    color: #48515d;
    border: none;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  th {
    background-color: #fdfdfd;
    word-break: break-all;
    // cursor: pointer;
    color: #848e9c;
    height: 40px;
    text-align: left;
    line-height: 40px;
    text-align: center;
  }
  th:first-child {
    text-indent: 10px;
  }
  .showSortMark {
    i {
      display: none;
    }
  }
  .sortHd {
    i {
      display: inline-block;
    }
  }
  }
  &__tbody {
    tr {
      border: 1px solid #fff;
      box-shadow: 0px 1px 20px rgba(90, 102, 124, 0.08);
      border-radius: 3px;
      transition: all 0.2s ease-in;
      td {
        position: relative;
        background-color: #fff;
        word-break: break-all;
        // cursor: pointer;
        font-size: 12px;
        color: #212833;
        // text-align: right;
        line-height: 40px;
        border: none;
      }
      td:first-child {
        border-left: 1px solid #fff;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        text-indent: 10px;
      }
      td:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:hover {
        box-shadow: 0px 1px 20px rgba(90, 102, 124, 0.2);
      }
    }
  }
}

.admin {
  width: 350px;
  max-width: 95%;
  margin: 10px auto 20px;
  padding: 20px 10px 20px;
  background-color: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  border-radius: 15px;
  text-align: center;
  transition: all .3s;
  position: relative;

  &__title {
    font-size: 18px;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
  }

  &__desc {
    padding: 0 10px;
    font-weight: 500;
    color: #8d8686;
    margin: 10px 0 40px;
  }

  &__address {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    img {
      margin-right: 5px;
    }
  }

  &__disconnect {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    transition: all .3s;

    &:hover {
      color: #ff527c;
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 600;
    background-color: #efe7e7;
    padding: 6px 20px;
    border-radius: 5px;
    transition: all .3s;

    &:hover {
      color: #FE6B8B;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 20px auto 10px;
    padding: 20px 15px;
  }
}